'use client';

import React, { useState } from 'react';
import { APP_NAME } from '@local/codebook/app';
import createPath from '@local/router/createPath';
import { RouteName } from '@local/router/routes';
import Link from 'next/link';
import { createPathToTutorial } from '@local/tutorial/services/routing';

const Navigation = () => {
    const [isMenuActive, setIsMenuActive] = useState(false);

    function handleMenuClick() {
        setIsMenuActive((prev) => !prev);
    }

    return (
        <nav className="navbar is-dark mb-2" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link
                    className="navbar-item is-size-4 has-text-weight-bold"
                    href={createPath(RouteName.Homepage)}
                >
                    {APP_NAME}
                </Link>
                <div
                    className={`navbar-burger ${isMenuActive ? 'is-active' : ''}`}
                    role="button"
                    aria-label="menu"
                    aria-expanded={isMenuActive ? 'true' : 'false'}
                    onClick={handleMenuClick}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>
            </div>
            <div className={`navbar-menu ${isMenuActive ? 'is-active' : ''}`}>
                <div className="navbar-start">
                    <Link className="navbar-item" href={createPath(RouteName.Scenarios)}>
                        Scenarios
                    </Link>
                    <Link className="navbar-item" href={createPath(RouteName.LearningScenarios)}>
                        Learning Scenarios
                    </Link>
                    <Link className="navbar-item" href={createPathToTutorial(1)}>
                        Tutorial
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
